import { IonButtons, IonCol, IonGrid, IonRow } from "@ionic/react";
import TickIcon from "../../../assets/Converter/Tick.svg";
import BackArrowIcon from "../../../assets/SvgEditor/BackArrow.svg";
import SaveIcon from "../../../assets/SvgEditor/Save.svg";
import DesktopToolbar from "./DesktopToolBar";
import Submit from "./Submit";

const downloadPNG = () => {
  const canvas = document.getElementById("canvas"); // Ensure this ID matches your canvas element
  if (canvas) {
    const image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const link = document.createElement("a");
    link.download = "download.png";
    link.href = image;
    link.click();
  }
};

const DesktopTopBar = ({
  scale,
  setScale,
  file,
  clearFields,
  crop,
  setIsCropVisible,
  setSvgPreview,
  setPreviewReady,
  setSvg,
  colors,
  setIsLoading,
  isLoading,
  setDownloadSVGLink,
  setShareLink,
  setIsModalVisible,
  ai,
  setFile,
  originalDimensions,
  despeckle,
  smoothness,
  simplifyCurves,
  saveToCloud,
  previewLoading,
}) => {
  return (
    <IonGrid
      style={{
        backgroundColor: "#F4F4F4",
        width: "100%",
        padding: 0,
        color: "#202020",
        fontFamily: "Clash Grotesk",
      }}
    >
      <IonRow>
        <IonCol size="auto" style={{ padding: 0, width: "188px" }}>
          <IonButtons
            onClick={clearFields}
            style={{
              width: "188px",
              height: "100%",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src={BackArrowIcon}
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontSize: "14px" }}>Back to files</span>
          </IonButtons>
        </IonCol>
        <IonCol style={{ marginLeft: "25px" }}>
          <DesktopToolbar
            scale={scale}
            setScale={setScale}
            file={file}
            crop={crop}
            setIsCropVisible={setIsCropVisible}
          />
        </IonCol>
        <IonCol
          style={{
            fontSize: "12px",
            display: "flex",
            gap: "6px",
            justifyContent: "flex-end",
            marginRight: "14px",
            padding: "10px 0",
          }}
        >
          {/* <button
            style={{
              fontWeight: 600,
              fontFamily: "Clash Grotesk",
              color: "#F453C4",
              background: "#fff",
              border: "1px solid #F453C4",
              padding: "6px 12px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
            onClick={async () => {
             // download the canvas
            }}
          >
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3" stroke="#F453C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            
          </button> */}
          <button
            style={{
              fontWeight: 600,
              fontFamily: "Clash Grotesk",
              color: "#F453C4",
              background: "#fff",
              border: "1px solid #F453C4",
              padding: "6px 12px",
              borderRadius: "4px",
            }}
            onClick={async () => downloadPNG()}
          >
            <img src={SaveIcon} alt="save" style={{ marginRight: "4px" }} />
            Download
          </button>
          <Submit
            setFile={setFile}
            setSvgPreview={setSvgPreview}
            setPreviewReady={setPreviewReady}
            setSvg={setSvg}
            originalDimensions={originalDimensions}
            crop={crop}
            despeckle={despeckle}
            simplifyCurves={simplifyCurves}
            smoothness={smoothness}
            colors={colors}
            file={file}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setDownloadSVGLink={setDownloadSVGLink}
            setShareLink={setShareLink}
            setIsModalVisible={setIsModalVisible}
            ai={ai}
            saveToCloud={saveToCloud}
            previewLoading={previewLoading}
          >
            <img
              src={TickIcon}
              alt="tick"
              style={{ marginRight: "4px", width: "18px", height: "18px" }}
            />
            Done: Convert
          </Submit>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DesktopTopBar;
