import { useGesture } from "@use-gesture/react";
import { useContext, useEffect, useState } from "react";
import { uploadSVGToStorage } from "../../lib/firebase/firebase";

import "antd/dist/antd.css";
import "./index.css";

import AutoUploadImage from "./components/AutoUploadImage";
// import UploadImage64 from './components/UploadImage64';
import AppContext from "../../contexts/AppContext";

import { writeSVGToDatabase } from "../../lib/firebase/firebase";

import { useDispatch } from "react-redux";
import {
  addNewlyGeneratedFile,
  setShowEditor,
} from "../../redux/slices/MyFiles";
import { addNewlyUploadedFile } from "../../redux/slices/UploadedFiles";
import ConverterDesktop from "./components/ConverterDesktop";
import ConverterMobile from "./components/ConverterMobile";

const Converter = (props) => {
  const {
    setSvgStore,
    image64,
    setImage64,
    imageSource,
    setImageSource,
    imageSEO,
  } = useContext(AppContext);
  const { imagePrompt } = useContext(AppContext);
  const [file, setFile] = useState("");
  const [fileLocation, setFileLocation] = useState("");
  const [originalDimensions, setOriginalDimensions] = useState(undefined);
  const [colors, setColors] = useState([]);
  const [clickedColor, setClickedColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [downloadSVGLink, setDownloadSVGLink] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [smoothness, setSmoothness] = useState(1);
  const [despeckle, setDespeckle] = useState(2);
  const [simplifyCurves, setSimplifyCurves] = useState(0.2);
  const [imageDimensions, setImageDimensions] = useState([0, 0]);
  const [scale, setScale] = useState(1);
  const [largeImageWarning, setLargeImageWarning] = useState(false);
  const [svg, setSvg] = useState();
  const [previewReady, setPreviewReady] = useState(false);

  const [svgPreview, setSvgPreview] = useState();
  const [previewScale, setPreviewScale] = useState(1);
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });

  // const [showPreviewScreen, setShowPreviewScreen] = useState(false);

  const [canvasHeight, setCanvasHeight] = useState(0);
  const [ai, setAi] = useState(true);

  const dispatch = useDispatch();

  const bind = useGesture({
    onDrag: ({ event, xy, delta, offset, touches }) => {
      setPreviewPosition({
        x: previewPosition.x + delta[0] / previewScale,
        y: previewPosition.y + delta[1] / previewScale,
      });
    },
    onPinch: ({ event, delta, touches, vxvy, last, origin, offset }) => {
      var svg = document.getElementById("svgtest");
      var group = svg.getElementById("transformGroup");
      var x = origin[0] - svg.getBoundingClientRect().left;
      var y = origin[1] - svg.getBoundingClientRect().top;
      group.setAttribute("transform-origin", x + " " + y);
      setPreviewScale(offset[0]);
    },
  });

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: null,
    y: null,
    width: null,
    height: null,
  });

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const clearFields = () => {
    setSvgPreview(undefined);
    setFile("");
    setFileLocation("");
    setColors([]);
    setImage64(undefined);
    setImageSource(undefined);
  };

  const resetFormFields = () => {
    clearFields();
    setDespeckle(2);
    setSmoothness(1);
    setSimplifyCurves(0.2);
  };

  useEffect(() => {
    if (document.getElementById("middleBox") !== null) {
      const element = document.getElementById("middleBox");
      const height = element.getBoundingClientRect().height;
      setCanvasHeight(height);
    }
  }, []);

  useEffect(() => {
    if (file !== "") {
      setTimeout(() => {
        props.setConverterOpen(true);
      }, 1000);
    } else {
      props.setConverterOpen(false);
      setPreviewReady(false);
    }
  }, [file]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (clickedColor !== "") {
      setColors([...colors, [clickedColor, true]]);
    }
  }, [clickedColor]);

  useEffect(() => {
    if (image64 === undefined) {
      setImageSource(undefined); // Set imageSource to undefined
    }
  }, [image64]);

  async function saveToCloud(openEditor) {
    if (imageSource === undefined) {
      throw new Error(
        "Image source is undefined. Cannot determine the folder name for saving."
      );
    }

    const base64Data = btoa(svg);
    let folderName = "svg"; // Default to "svg" for uploaded images
    if (imageSource === "generated") {
      folderName = "svg_generated";
    } else if (imageSource === "uploaded") {
      folderName = "svg";
    }

    const dataUrl = `data:image/svg+xml;base64,${base64Data}`;

    try {
      const response = await uploadSVGToStorage(dataUrl, folderName);
      if (imageSource === "generated") {
        try {
          const filePath = response.res.ref.fullPath;
          console.log(" response.res.", response.res);
          const docRef = await writeSVGToDatabase(
            filePath,
            imagePrompt,
            imageSEO
          );

          if (docRef) {
            clearFields();
            console.log("NewGenerated", response.res);
            const newGenerated = {
              id: response.res.ref.name,
              name: imagePrompt,
              uri: response.res.ref,
              url: response.url,
              type: "generated",
            };
            dispatch(addNewlyGeneratedFile(newGenerated));

            if (openEditor == true) {
              dispatch(setShowEditor({ currentFile: newGenerated }));
              setSvgStore({ firebase: newGenerated.uri });
            }
          }
        } catch (error) {
          console.error("Error writing SVG to Database", error);
        }
      }

      if (imageSource === "uploaded") {
        try {
          clearFields();
          const newUploaded = {
            id: response.res.ref.name,
            name: response.res.ref.name,
            uri: response.res.ref,
            url: response.url,
          };
          dispatch(addNewlyUploadedFile(newUploaded));

          if (openEditor == true) {
            dispatch(setShowEditor({ currentFile: newUploaded }));
            setSvgStore({ firebase: response.res.ref });
          }
        } catch (error) {
          console.error("Error writing SVG to Database", error);
        }
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
    }
  }

  return (
    <>
      {/* upload button */}
      {file === "" &&
        props.converterOpen === false &&
        svgPreview === undefined && (
          <>
            <AutoUploadImage
              setOriginalDimensions={setOriginalDimensions}
              setCrop={setCrop}
              setLargeImageWarning={setLargeImageWarning}
              setDespeckle={setDespeckle}
              setScale={setScale}
              setImageDimensions={setImageDimensions}
              setFile={setFile}
              setColors={setColors}
            />
          </>
        )}
      {/* Select Colors Screen */}
      {file !== "" && props.converterOpen && window.innerWidth <= 750 && (
        <ConverterMobile
          screenSize={screenSize}
          crop={crop}
          setCrop={setCrop}
          file={file}
          setFile={setFile}
          scale={scale}
          setScale={setScale}
          setClickedColor={setClickedColor}
          setImageDimensions={setImageDimensions}
          originalDimensions={originalDimensions}
          setOriginalDimensions={setOriginalDimensions}
          largeImageWarning={largeImageWarning}
          downloadSVGLink={downloadSVGLink}
          isLoading={isLoading}
          setIsCropVisible={setIsCropVisible}
          isCropVisible={isCropVisible}
          smoothness={smoothness}
          setSmoothness={setSmoothness}
          despeckle={despeckle}
          setDespeckle={setDespeckle}
          simplifyCurves={simplifyCurves}
          setSimplifyCurves={setSimplifyCurves}
          imageDimensions={imageDimensions}
          ai={ai}
          setAi={setAi}
          colors={colors}
          setColors={setColors}
          setSvgPreview={setSvgPreview}
          setPreviewReady={setPreviewReady}
          setSvg={setSvg}
          setShareLink={setShareLink}
          setIsModalVisible={setIsModalVisible}
          setDownloadSVGLink={setDownloadSVGLink}
          setIsLoading={setIsLoading}
          setLoadingConverter={props.setLoadingConverter}
        />
      )}
      {file !== "" && props.converterOpen && window.innerWidth > 750 && (
        <ConverterDesktop
          scale={scale}
          setScale={setScale}
          file={file}
          setFile={setFile}
          clearFields={clearFields}
          crop={crop}
          setCrop={setCrop}
          setIsCropVisible={setIsCropVisible}
          screenSize={screenSize}
          setClickedColor={setClickedColor}
          imageDimensions={imageDimensions}
          setImageDimensions={setImageDimensions}
          originalDimensions={originalDimensions}
          setOriginalDimensions={setOriginalDimensions}
          setCropGlobal={setCrop}
          largeImageWarning={largeImageWarning}
          isCropVisible={isCropVisible}
          downloadSVGLink={downloadSVGLink}
          setDownloadSVGLink={setDownloadSVGLink}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          smoothness={smoothness}
          setSmoothness={setSmoothness}
          despeckle={despeckle}
          setDespeckle={setDespeckle}
          simplifyCurves={simplifyCurves}
          setSimplifyCurves={setSimplifyCurves}
          ai={ai}
          setAi={setAi}
          colors={colors}
          setColors={setColors}
          clickedColor={clickedColor}
          setSvgPreview={setSvgPreview}
          setPreviewReady={setPreviewReady}
          setSvg={setSvg}
          setShareLink={setShareLink}
          svgPreview={svgPreview}
          saveToCloud={saveToCloud}
          setLoadingConverter={props.setLoadingConverter}
        />
      )}
      {/* Preview Screen */}
      {/* {file !== "" &&
        props.converterOpen &&
        showPreviewScreen &&
        svgPreview !== undefined && (
          <PreviewConvertedSvg
            svgPreview={svgPreview}
            setSvgPreview={setSvgPreview}
            fileLocation={fileLocation}
            clearFields={clearFields}
            saveToCloud={saveToCloud}
            previewScale={previewScale}
            previewPosition={previewPosition}
            bind={bind}
            setShowPreviewScreen={setShowPreviewScreen}
          />
        )} */}
    </>
  );
};

export default Converter;
