import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import "./Carousel.css";
import { IonRow, IonCol } from "@ionic/react"; // Import necessary Ionic components
import UploadSection from "../UploadSection"; // Adjust the import path as necessary
import ImageGenerator from "../../../../components/ImageGenerator";
import Resizer from "../../../Resizer"; // Adjust the import path as necessary

const Carousel1 = ({
  data,
  setImage64,
  setFileName,
  setRerender,
  rerender,
  fileName,
}) => {
  const carouselRef = useRef();
  const [showPrevious, setShowPrevious] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false); // Add a state to control auto-scrolling
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [rowLeft, setRowLeft] = useState(0); // State to hold the left position of the IonRow
  const [showArrows, setShowArrows] = useState(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      setShowArrows(window.innerWidth < 1000);
    };
    window.addEventListener("resize", updateSize);
    updateSize(); // Call on mount to check initial size

    return () => window.removeEventListener("resize", updateSize); // Corrected from updateUpdate to updateSize
  }, []);

  useEffect(() => {
    if (data && data.data.length > 0) {
      const handleScroll = () => {
        const container = carouselRef.current;
        const isAtStart = container.scrollLeft <= 0;
        const isAtEnd =
          container.scrollLeft + container.offsetWidth >= container.scrollWidth;
        setShowPrevious(!isAtStart);
        setShowNext(!isAtEnd);
      };

      carouselRef.current.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial call to set buttons visibility

      let autoScroll;
      if (autoScrollEnabled) {
        // Set up the interval for automatic scrolling only if enabled
        autoScroll = setInterval(() => {
          if (showNext) {
            next(); // Scroll to the next set of items
          }
        }, 3000); // Change 3000 to whatever interval you prefer in milliseconds
      }

      return () => {
        const container = carouselRef.current;
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
        if (autoScroll) {
          clearInterval(autoScroll); // Clear the interval when the component unmounts
        }
      };
    } else {
      console.log("No data available for carousel");
    }
  }, [data, showNext, autoScrollEnabled]); // Depend on data to re-run this effect

  const next = () => {
    const scrollDistance = carouselRef.current.clientWidth;
    carouselRef.current.scrollTo({
      left: carouselRef.current.scrollLeft + scrollDistance,
      behavior: "smooth",
    });
  };

  const previous = () => {
    const scrollDistance = carouselRef.current.clientWidth;
    carouselRef.current.scrollTo({
      left: carouselRef.current.scrollLeft - scrollDistance,
      behavior: "smooth",
    });
  };

  const onMouseDown = (e) => {
    e.preventDefault(); // Prevent default behavior like text selection or dragging images
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault(); // Continue to prevent default behavior while moving
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the speed as necessary
    carouselRef.current.scrollLeft = scrollLeft - walk;
    setRowLeft(x - startX); // Update the position of the row based on mouse movement
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const slider = carouselRef.current;
    slider.addEventListener("mousedown", onMouseDown);
    slider.addEventListener("mousemove", onMouseMove);
    slider.addEventListener("mouseup", onMouseUp);
    slider.addEventListener("mouseleave", onMouseUp);

    return () => {
      slider.removeEventListener("mousedown", onMouseDown);
      slider.removeEventListener("mousemove", onMouseMove);
      slider.removeEventListener("mouseup", onMouseUp);
      slider.removeEventListener("mouseleave", onMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="carousel-container" style={{ position: "relative" }}>
      {showArrows && (
        <>
          <FontAwesomeIcon
            icon={faCaretLeft}
            onClick={previous}
            className="previous"
          />
          <FontAwesomeIcon
            icon={faCaretRight}
            onClick={next}
            className="next"
          />
        </>
      )}
      <div className="cardsHolder" ref={carouselRef}>
        <IonRow
          style={{ minWidth: "1000px", overflow: "hidden", width: "100%" }}
        >
          <IonCol
            style={{
              paddingBottom: "15px",
              borderRadius: "10px",
              background: "white",
              minWidth: "500px",
            }}
          >
            <UploadSection
              setImage={setImage64}
              setFileName={setFileName}
              background="/assets/convert_background.png"
              text1="Upload an Image "
              text2="Click or drag a PNG, JPG, WEBP"
              id="AutoConvertUpload"
            />
          </IonCol>
          <IonCol style={{ minWidth: "500px", paddingBottom: "15px" }}>
            <div className="col-container">
              <ImageGenerator />
            </div>
          </IonCol>
          <Resizer
            setRerender={setRerender}
            rerender={rerender}
            fileName={fileName}
          />
        </IonRow>
      </div>
    </div>
  );
};

export default Carousel1;
