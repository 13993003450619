import { Upload } from "antd";
import { useContext, useState } from "react";
import uploadActiveIcon from "../../../assets/upload_active_icon.svg";
import uploadIcon from "../../../assets/upload_icon.svg";
// app context
import { AppContext } from "contexts/AppContext";

const UploadSection = ({ setImage, setFileName, background, text1, text2 }) => {
  const [over, setOver] = useState(false);
  const { setImageSource } = useContext(AppContext);

  return (
    <div
      className="col-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "12px",
        border: "1px solid #D6D6D6",
        boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
        fontFamily: "DM Sans, sans-serif",
        color: "#2A2A2A",
        outline: over ? "1px solid #F453C4" : "none",
      }}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
    >
      <span style={{ width: "100%", height: "100%" }}>
        <Upload.Dragger
          name="file"
          listType="text"
          accept="image/jpeg, image/png, image/webp"
          beforeUpload={(file) => {
            if (
              !["image/jpeg", "image/png", "image/webp"].includes(file.type)
            ) {
              console.log("Unsupported file format");
              return Upload.LIST_IGNORE;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              if (setFileName) {
                setFileName(file.name);
              }
            };
            setImageSource("uploaded");
            reader.readAsDataURL(file);
            return false;
          }}
          showUploadList={false}
        >
          <div>
            <img src={over ? uploadActiveIcon : uploadIcon} alt="logo" />
            <p className="button-text">{text1}</p>
            {text2 && (
              <p
                className="button-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontStyle: "italic",
                }}
              >
                {text2}
              </p>
            )}
          </div>
        </Upload.Dragger>
      </span>
    </div>
  );
};

export default UploadSection;
