import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BENEFITS } from "./constants";

const Benefits = () => (
  <div className="bnfts">
    {BENEFITS.map((benefit, index) => (
      <div key={index} className="bnf">
        <FontAwesomeIcon icon={faCheckCircle} color="#44C4A1" fontSize="12px" />
        <p
          style={{
            margin: "0",
            padding: "0",
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "400",
            color: "#000",
          }}
        >
          {benefit}
        </p>
      </div>
    ))}
  </div>
);

export default Benefits;
