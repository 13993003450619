import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ChevronLeft } from "react-feather";

const PreviewSvgModal = ({
  openPreviewModal,
  setOpenPreviewModal,
  svgPreview,
}) => {
  return (
    <div>
      <IonModal
        style={{
          "--height": "90vh",
          "--max-height": "649px",
          "--width": "1164px",
          "--max-width": "95%",
          "--border-radius": "8px",
        }}
        isOpen={openPreviewModal}
        onDidDismiss={() => setOpenPreviewModal(false)}
      >
        <IonToolbar
          style={{
            "--background": "#fff",
            height: "65px",
            display: "flex",
          }}
        >
          <IonTitle
            style={{
              fontSize: "22px",
              color: "#2A2A2A",
              padding: 0,
              marginLeft: "22px",
            }}
          >
            Preview Image
          </IonTitle>
          <IonButtons
            slot="end"
            style={{
              border: "1px solid #F453C4",
              borderRadius: "4px",
              color: "#F453C4",
              marginRight: "20px",
            }}
          >
            <IonButton
              style={{
                fontSize: "12px",
                fontWeight: "600",
                "--padding-start": "12px",
                "--padding-end": "12px",
                textTransform: "none",
              }}
              onClick={() => setOpenPreviewModal(false)}
            >
              <ChevronLeft size={20} style={{ marginRight: "4px" }} />
              Keep Editing
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent
          style={{
            "--background": "#F6F6F6",
            borderTop: "1px solid #BEBEBE",
            borderBottom: "1px solid #BEBEBE",
          }}
        >
          <img src={svgPreview} style={{ width: "100%", height: "100%" }} />
        </IonContent>
      </IonModal>
    </div>
  );
};

export default PreviewSvgModal;
