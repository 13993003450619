import { IonButton } from "@ionic/react";
import { useCallback, useEffect } from "react";
import { alertController, loadingController } from "@ionic/core";
import { isPlatform } from "@ionic/react";
import process from "../helpers/runonnx";
import "antd/dist/antd.css";

const Resizer = (props) => {
  const { file } = props;

  async function saveImage(blob, scale) {
    // const originalFileName = file.name;
    const newFileName = `resized_${scale}x.png`;

    if (
      isPlatform("desktop") ||
      isPlatform("mobileweb") ||
      isPlatform("electron")
    ) {
      let buttonArray = [
        {
          text: "Download",
          handler: async () => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = newFileName;
            a.click();
            URL.revokeObjectURL(url);
            a.parentNode.removeChild(a);
          },
        },
      ];

      const alert = await alertController.create({
        header: "Success!",
        subHeader: "The images have been resized.",
        buttons: buttonArray,
      });

      await alert.present();
    }
  }

  const resizeImage = useCallback(async () => {
    const settings = await alertController.create({
      header: "Resize Options",
      inputs: [
        {
          name: "2x",
          type: "radio",
          label: "2x",
          value: "2x",
          checked: true,
        },
        {
          name: "4x",
          type: "radio",
          label: "4x",
          value: "4x",
          checked: false,
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          onClick: () => {
            console.log("Cancel clicked");
          },
        },
        {
          text: "Resize",
          handler: async (data) => {
            const loading = await loadingController.create({
              message: "Preparing files...",
              id: "custom-loading",
            });

            await loading.present();

            console.log("file, ", file);
            const img = new Image();
            img.src = file; // Use the base64 string directly

            img.onload = async () => {
              let width = img.width;
              let height = img.height;

              if (width > 750 || height > 750) {
                loading.message = "Image too large, compressing image...";

                const aspectRatio = width / height;
                let newWidth = width;
                let newHeight = height;

                if (width > height) {
                  newWidth = 750;
                  newHeight = 750 / aspectRatio;
                } else {
                  newHeight = 750;
                  newWidth = 750 * aspectRatio;
                }

                width = newWidth;
                height = newHeight;
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const context = canvas.getContext("2d");

              let myscale = data === "2x" ? 2 : data === "4x" ? 4 : 1;

              context.drawImage(img, 0, 0, width, height);
              const imageData = context.getImageData(0, 0, width, height);

              process(imageData, myscale).then(async (result) => {
                loading.dismiss();
                const resizedBlob = await fetch(result).then((r) => r.blob());
                await saveImage(resizedBlob, myscale);
              });
            };
          },
        },
      ],
    });
    await settings.present();
  });

  return (
    <IonButton
      style={{
        "--border-color": "rgba(244, 83, 196, 0.32)",
        "--border-style": "solid",
        "--border-width": "1px",
        "--color": "#202020",
        "--background": "linear-gradient(90deg, #FFDBF4 0%, #DEDBFF 100%)",
        "--background-hover":
          "linear-gradient(90deg, #FFDBF4 0%, #DEDBFF 100%)",
        "--background-activated":
          "linear-gradient(90deg, #FFDBF4 0%, #DEDBFF 100%)",
        "--box-shadow": "none",
        "--border-radius": "8px",
        height: "47px",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "none",
        width: "176px",
      }}
      onClick={() => resizeImage()}
    >
      {"Resizer (2x, 4x)"}
    </IonButton>
  );
};

export default Resizer;
