import { IonLoading } from "@ionic/react";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import useSaveSVG from "../../../hooks/useSaveSvg";
import AutoUploadImage from "../../Converter/components/AutoUploadImage";
import useSvgUpdater from "../SvgUpdater";
import Canvas from "./Canvas";
import CropSvg from "./CropSvg";
import DesktopTopBar from "./DesktopTopBar";
import LayerSelectorDesktop from "./LayerSelectorDesktop";
import Navbar from "../../WebNavbar";

const SvgEditorDesktop = () => {
  const {
    svg,
    svgJoined,
    undoActions,
    redoActions,
    colors,
    changesMade,
    setChangesMade,
    setColors,
    updateSvg,
    undo,
    redo,
    undoAll,
    currentFile,
  } = useSvgUpdater();
  const [cursor, setCursor] = useState(`crosshair`);
  const [layerSelected, setLayerSelected] = useState("all");
  const [toolSelected, setToolSelected] = useState("move");
  const [eraserRadius, setEraserRadius] = useState(25);
  const [vaccumRadius, setVaccumRadius] = useState(25);

  const [file, setFile] = useState("");
  const [originalDimensions, setOriginalDimensions] = useState(undefined);
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [largeImageWarning, setLargeImageWarning] = useState(false);

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: null,
    y: null,
    width: null,
    height: null,
  });

  const { loading: savingInProgress } = useSaveSVG(svgJoined, currentFile);
  useEffect(() => {
    if (layerSelected === "all") {
      setToolSelected("move");
    }
  }, [layerSelected]);

  return (
    <>
      <IonLoading isOpen={savingInProgress} message={"Saving..."} />
      <AutoUploadImage
        setOriginalDimensions={setOriginalDimensions}
        setCrop={setCrop}
        setLargeImageWarning={setLargeImageWarning}
        setFile={setFile}
        setColors={setColors}
        layerSelected={layerSelected}
      />
      <Navbar fileName={currentFile?.name} showFileName={false} />
      <DesktopTopBar
        toolSelected={toolSelected}
        setToolSelected={setToolSelected}
        svg={svg}
        setIsCropVisible={setIsCropVisible}
        updateSvg={updateSvg}
        undo={undo}
        undoAll={undoAll}
        redo={redo}
        redoActions={redoActions}
        undoActions={undoActions}
        colors={colors}
        setColors={setColors}
        svgJoined={svgJoined}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        currentFile={currentFile}
        layerSelected={layerSelected}
        eraserRadius={eraserRadius}
        setEraserRadius={setEraserRadius}
        vaccumRadius={vaccumRadius}
        setVaccumRadius={setVaccumRadius}
      />
      <Row
        style={{
          outline: "1px solid #f0f0f0",
          minWidth: "500px",
          maxHeight: "calc(100vh)",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <Col
          flex="188px"
          style={{
            height: "100vh",
            // make light grey outline
            borderStyle: "solid",
            borderColor: "#cfcfcf",
            // only make the left border visible
            borderLeftWidth: "1px",
            borderRightWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            width: "188px",
          }}
        >
          {colors && (
            <LayerSelectorDesktop
              setLayerSelected={setLayerSelected}
              colors={colors}
              setColors={setColors}
              layerSelected={layerSelected}
              svg={svg}
              updateSvg={updateSvg}
              svgJoined={svgJoined}
            />
          )}
        </Col>

        <Col
          flex="auto"
          style={{ maxWidth: "calc(100% - 188px)", height: "100vh" }}
        >
          <Col
            flex="auto"
            style={{
              backgroundColor: "#f0f0f0",
              height: "calc(100%)",
              overflowX: "scroll",
              cursor: cursor,
            }}
          >
            <div style={{ height: "100%" }}>
              <Canvas
                svg={svg}
                svgJoined={svgJoined}
                setSvg={updateSvg}
                layerSelected={layerSelected}
                toolSelected={toolSelected}
                eraserRadius={eraserRadius}
                vaccumRadius={vaccumRadius}
                setCursor={setCursor}
              />

              <CropSvg
                originalDimensions={originalDimensions}
                setOriginalDimensions={setOriginalDimensions}
                cropGlobal={crop}
                setCropGlobal={setCrop}
                largeImageWarning={largeImageWarning}
                file={file}
                isModalVisible={isCropVisible}
                setIsModalVisible={setIsCropVisible}
                updateSvg={updateSvg}
                svg={svg}
              />
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default SvgEditorDesktop;
