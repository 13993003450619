import { useIonLoading } from "@ionic/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
// import colortheif library
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { alertController, loadingController } from "@ionic/core";
import { isPlatform } from "@ionic/react";
//import uuid
import { Button } from "antd";
import process from "../components/Converter/helpers/runonnx";

import { Upload } from "antd";
import AppContext from "../contexts/AppContext";

import "antd/dist/antd.css";
import "./Resizer.css";
// import { svgToPng } from '../helpers/SvgToPng';
// import './index.css';

const Resizer = (props) => {
  const { image64Resize } = useContext(AppContext);
  const { fileName } = props;
  // const [debugText, setDebugText] = useState('hello');
  // const [files, setFiles] = useState('');
  // const [currentFile, currentFile] = useState(1);

  // percent complete
  // interval for progress bar
  // const [interval, setInterval] = useState(0);

  useEffect(() => {
    // setCompHeight(`calc(100vh - ${document.getElementById('header').offsetHeight }px)`);
  });

  async function saveImage(dataUrl, scale) {
    // get the original file name
    // const originalFileName = document.getElementById('ResizerInput').files[0].name;
    const originalFileName = fileName;
    //  new file name is the original file name with the scale appended to it
    const newFileName = `${originalFileName.split(".")[0]}_${scale}x.png`;

    if (
      isPlatform("desktop") ||
      isPlatform("mobileweb") ||
      isPlatform("electron")
    ) {
      let buttonArray = [];

      buttonArray = [
        {
          text: "Download",
          handler: async () => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = dataUrl;
            a.download = newFileName;
            a.click();
            // Clean up and remove the link
            a.parentNode.removeChild(a);
          },
        },
      ];

      const alert = await alertController.create({
        header: "Success!",
        subHeader: "The images have been resized.",
        // make a button that opens the file
        buttons: buttonArray,
      });

      await alert.present();
    }

    if (isPlatform("ios") && !isPlatform("mobileweb")) {
      Filesystem.writeFile({
        path: "resized/" + newFileName,
        data: dataUrl,
        recursive: true,
        directory: Directory.Documents,
      }).then(async (pngUri) => {
        let buttonArray = [];

        buttonArray = [
          {
            text: "Open File",
            handler: async () => {
              // create a link and click it
              const link = document.createElement("a");
              link.href = `shareddocuments:///${pngUri.uri.slice(8)}`;
              link.click();
              // remove the link
              link.remove();
              // open up share sheet
            },
          },
          {
            text: "Export",
            handler: async () => {
              await Share.share({
                title: "Share PNG",
                url: pngUri.uri,
                dialogTitle: "Share PNG",
              });
            },
          },
        ];
        const alert = await alertController.create({
          header: "Success!",
          subHeader: "The images have been resized.",
          // make a button that opens the file
          buttons: buttonArray,
        });
        await alert.present();
      });
    }
  }

  const draggerProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    // maxCount: 20,
    showUploadList: false,
  };

  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  function base64StringToFile(base64String, filename) {
    if (typeof base64String !== "string" || !base64String.startsWith("data:")) {
      throw new Error("Invalid or undefined base64String");
    }
    const matches = base64String.match(/:([^;]+);/);
    if (!matches) {
      throw new Error("Could not extract MIME type from base64 string");
    }
    const blobType = matches[1];
    const blob = base64ToBlob(base64String, blobType);
    return new File([blob], filename, { type: blobType });
  }

  const resizeImage = useCallback(async () => {
    // show alert with options for converting
    const settings = await alertController.create({
      header: "Resize Options",
      inputs: [
        {
          name: "2x",
          type: "radio",
          label: "2x",
          value: "2x",
          checked: true,
        },
        {
          name: "4x",
          type: "radio",
          label: "4x",
          value: "4x",
          checked: false,
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          onClick: () => {
            console.log("Cancel clicked");
          },
        },
        {
          text: "Resize",
          handler: async (data) => {
            const loading = await loadingController.create({
              message: "Preparing files...",
              id: "custom-loading",
            });

            await loading.present();

            // get the file
            const file = base64StringToFile(image64Resize, fileName);
            const blob = new Blob([file]);
            const url = URL.createObjectURL(blob);
            const img = new Image();
            img.src = url;
            // get the file details
            const fileDetails = {
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
            };

            // file size in mb
            const fileSize = file.size / 1000;

            console.log(fileDetails.size);
            // log kb
            console.log(fileDetails.size / 1000);
            // log mb
            console.log(fileDetails.size / 1000000);

            // if the file is over 100kb, warn the user that it likely won't work
            if (fileSize > 5000) {
              // close the loading
              loading.dismiss();

              // make an alert
              const alert = await alertController.create({
                header: "File Too Large",
                subHeader:
                  "File size exceeds 5mb. Please choose a smaller file.",
                buttons: ["OK"],
              });

              await alert.present();

              return;
            }
            img.onload = async () => {
              // get the width and height of the image
              var width = img.width;
              var height = img.height;

              // if the width or height is over 1000, resize it and keep the aspect ratio
              if (width > 750 || height > 750) {
                // update loading message
                loading.message = "Image too large, compressing image...";

                // get the aspect ratio
                const aspectRatio = width / height;
                // if the width is greater than the height
                if (width > height) {
                  // set the width to 1000
                  width = 750;
                  // set the height to 1000 / aspect ratio
                  height = 750 / aspectRatio;
                } else {
                  // set the height to 1000
                  height = 750;
                  // set the width to 1000 * aspect ratio
                  width = 750 * aspectRatio;
                }
              }
              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const context = canvas.getContext("2d");

              // get the scale
              let myscale = 1;
              if (data === "2x") {
                myscale = 2;
              } else if (data === "4x") {
                myscale = 4;
              }

              context.drawImage(img, 0, 0, width, height);
              const imageData = context.getImageData(0, 0, width, height);

              console.log(imageData);

              // run it through the process
              process(imageData, myscale).then(async (result) => {
                loading.dismiss();
                await saveImage(result, myscale);
              });
            };

            // const alert = await alertController.create({
            //   header: 'Success!',
            //   subHeader: 'The images have been converted to SVGs and saved to your device.',
            //   // buttons: buttonsArray,
            // });

            // await alert.present();
            // rerender
            props.setRerender(props.rerender + 1);
          },
        },
      ],
    });
    await settings.present();
  });

  return (
    <Button id="ResizerInput" hidden onClick={resizeImage}>
      Resize Image
    </Button>
  );
};

export default Resizer;
