import {
  IonButton,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { Divider } from "antd";
import { useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import CustomizeIcon from "../../../assets/Converter/Customize.svg";
import EyeIcon from "../../../assets/Converter/Eye.svg";
import ColorFillIcon from "../../../assets/SvgEditor/ColorFill.svg";
import ColorLayer from "./ColorLayer";
import CustomSlider from "./CustomSlider";
import PreviewSvgModal from "./PreviewSvgModal";
import Resizer from "./Resizer";
import { removeBackground } from "@imgly/background-removal";
import { blobToBase64 } from "../helpers/helpers";

const DesktopLayerSelector = ({
  colors,
  setColors,
  smoothness,
  setSmoothness,
  despeckle,
  setDespeckle,
  imageDimensions,
  simplifyCurves,
  setSimplifyCurves,
  svgPreview,
  previewLoading,
  file,
  setFile,
  isLoading,
}) => {
  const hoverPreviewRef = useRef(null);
  const isPreviewHover = useHover(hoverPreviewRef);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        message={`Removing Background`}
        spinner="circular"
      />
      {svgPreview && !previewLoading && (
        <PreviewSvgModal
          openPreviewModal={openPreviewModal}
          setOpenPreviewModal={setOpenPreviewModal}
          svgPreview={svgPreview}
        />
      )}
      <IonGrid
        style={{
          width: "100%",
          margin: "0px",
          padding: "0px",
          fontFamily: "Clash Grotesk",
          color: "#202020",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <IonRow
          style={{
            backgroundColor: "#3D5EB3",
            fontWeight: 500,
            fontSize: "18px",
            color: "#fff",
            padding: "14px",
            display: "flex",
            gap: "11px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={CustomizeIcon} style={{ width: "24px", height: "24px" }} />
          Customize
        </IonRow>

        <IonRow
          style={{
            padding: "8px 6px",
            background: "rgba(0, 0, 0, 0.04)",
            borderRadius: "8px",
            fontSize: "10px",
            fontWeight: 500,
            margin: "0 6px",
            border: "1px solid #E3E3E3",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            SVG Preview
          </p>
          <div
            style={{
              maxHeight: "200px",
              minHeight: "100px",
              width: "100%",
              position: "relative",
              cursor: isPreviewHover
                ? "pointer"
                : previewLoading
                  ? "wait"
                  : "default",
            }}
            ref={hoverPreviewRef}
            onClick={() => {
              if (!previewLoading && isPreviewHover) {
                setOpenPreviewModal(true);
              }
            }}
          >
            {(previewLoading || isPreviewHover) && (
              <div
                style={{
                  background: "#00000047",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              />
            )}
            {previewLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  color: "#fff",
                  fontWeight: 500,
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <IonSpinner
                  name="circular"
                  style={{ width: "24px", height: "24px" }}
                />
                Loading
              </div>
            )}
            {!previewLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  color: "#fff",
                  fontWeight: 500,
                  display: isPreviewHover ? "flex" : "none",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <IonIcon icon={EyeIcon} size={20} />
                Preview
              </div>
            )}
            <img src={svgPreview} style={{ width: "100%", height: "100%" }} />
          </div>
          <Divider style={{ margin: "8px 0", borderTopColor: "#D1D1D1" }} />
          <p
            style={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Layers
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "100%",
            }}
          >
            {colors.map((layer, index) => (
              <ColorLayer
                code={layer[0]}
                onChange={(newValue) => {
                  const updatedColors = colors.map((item, idx) =>
                    idx === index ? [newValue, item[1]] : item
                  );
                  setColors(updatedColors);
                }}
                onRemove={() => {
                  const updatedColors = colors.filter(
                    (_, colorIndex) => index !== colorIndex
                  );
                  setColors(updatedColors);
                }}
              />
            ))}
          </div>
          <IonButton
            expand="block"
            fill="outline"
            style={{
              "--border-color": "#e83e8c",
              "--border-style": "dashed",
              "--border-width": "2px",
              "--color": "#525252",
              "--background": "#fff",
              "--background-hover": "#fff",
              "--background-activated": "#fff",
              height: "45px",
              fontWeight: 500,
              textTransform: "none",
              width: "100%",
              margin: "4px 0 0 0",
            }}
            onClick={() => {
              setColors((prev) => [...prev, ["", true]]);
            }}
          >
            <IonIcon icon={ColorFillIcon} style={{ marginRight: "6px" }} />
            New Color
          </IonButton>
        </IonRow>
        <IonRow
          style={{
            padding: "8px 6px",
            background: "rgba(0, 0, 0, 0.04)",
            borderRadius: "8px",
            fontSize: "10px",
            fontWeight: 500,
            margin: "0 6px",
            border: "1px solid #E3E3E3",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "120%",
              marginBottom: "0",
            }}
          >
            Editing Tools
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <CustomSlider
              label="Smoothness"
              value={smoothness}
              min={0}
              max={1.34}
              step={0.01}
              onChange={(value) => {
                setSmoothness(value);
              }}
            />
            <CustomSlider
              label="Despeckle"
              min={0}
              max={imageDimensions[0] / 4 > 20 ? imageDimensions[0] / 4 : 20}
              value={despeckle}
              step={1}
              onChange={(value) => {
                setDespeckle(value);
              }}
            />
            <CustomSlider
              label="Simplify Curves"
              value={simplifyCurves}
              min={0}
              max={1000}
              onChange={(value) => {
                console.log(value);
                setSimplifyCurves(value);
              }}
              step={0.01}
            />
          </div>
        </IonRow>
        <IonRow style={{ margin: "0 6px" }}>
          <IonButton
            style={{
              "--border-color": "rgba(244, 83, 196, 0.32)",
              "--border-style": "solid",
              "--border-width": "1px",
              "--color": "#202020",
              "--background":
                "linear-gradient(90deg, #DEDBFF 0%, #FFDBF4 100%)",
              "--background-hover":
                "linear-gradient(90deg, #FFDBF4 0%, #DEDBFF 100%)",
              "--background-activated":
                "linear-gradient(90deg, #FFDBF4 0%, #DEDBFF 100%)",
              "--box-shadow": "none",
              "--border-radius": "8px",
              height: "47px",
              fontWeight: 500,
              fontSize: "12px",
              textTransform: "none",
              width: "176px",
            }}
            disabled={isLoading || previewLoading}
            onClick={async () => {
              try {
                setShowLoading(true);
                const blob = await removeBackground(file);
                const base64String = await blobToBase64(blob);
                setFile(base64String);
              } catch (error) {
                console.error(error);
              } finally {
                setShowLoading(false);
              }
            }}
          >
            Remove Background
          </IonButton>
        </IonRow>
        <IonRow style={{ margin: "0 6px" }}>
          <Resizer file={file} setFile={setFile} />
        </IonRow>
      </IonGrid>
    </>
  );
};

export default DesktopLayerSelector;
