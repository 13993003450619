import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Divider } from "antd";
import CopyIcon from "../../../assets/SvgEditor/Copy.svg";
import DeleteIcon from "../../../assets/SvgEditor/Delete.svg";
import DragIcon from "../../../assets/SvgEditor/Drag.svg";
import LayerGroupIcon from "../../../assets/SvgEditor/LayerGroup.svg";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LayerPreview from "./LayerPreview";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";

const LayerSelectorDesktop = (props) => {
  const currentFile = useSelector((state) => state.files.currentFile);
  const { user } = useAuth();
  const isOwner = user?.uid === currentFile?.ownerUid;
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(props.colors);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    props.setColors(items);

    let svgCopy = JSON.parse(JSON.stringify(props.svg));

    // Remove the dragged item from the array at the source index
    const [removed] = svgCopy.children.splice(result.source.index, 1);

    // Insert the removed item at the destination index
    svgCopy.children.splice(result.destination.index, 0, removed);

    // Shift the remaining items over if necessary
    if (result.source.index > result.destination.index) {
      for (let i = result.source.index; i < result.destination.index; i++) {
        [svgCopy.children[i], svgCopy.children[i + 1]] = [
          svgCopy.children[i + 1],
          svgCopy.children[i],
        ];
      }
    } else {
      for (let i = result.source.index; i > result.destination.index; i--) {
        [svgCopy.children[i], svgCopy.children[i - 1]] = [
          svgCopy.children[i - 1],
          svgCopy.children[i],
        ];
      }
    }

    props.updateSvg(svgCopy);

    props.setLayerSelected(result.destination.index);
  };

  // Helper function to update SVG and colors
  const updateParentComponent = (svg, colors) => {
    props.updateSvg(svg);
    props.setColors(colors);
  };

  // Helper function to check if the selected layer is invalid
  const isInvalidLayerSelected =
    props.layerSelected === "all" ||
    props.layerSelected < 0 ||
    props.layerSelected >= props.svg.children.length;

  const handleCopy = (layerSelected) => {
    // Guard clauses for invalid layerSelected
    if (isInvalidLayerSelected) return;

    // Shallow copy of SVG object and its children
    const svgCopy = JSON.parse(JSON.stringify(props.svg));

    // Copy the specific layer
    const layerCopy = JSON.parse(
      JSON.stringify(svgCopy.children[layerSelected])
    );
    // Insert the copied layer right after the original one
    svgCopy.children.splice(layerSelected + 1, 0, layerCopy);

    // Shallow copy of colors array
    const colorsCopy = [...props.colors];
    colorsCopy.splice(layerSelected + 1, 0, props.colors[layerSelected]);

    // Update the SVG and colors in the parent component
    updateParentComponent(svgCopy, colorsCopy);
  };

  const handleDelete = (layerSelected) => {
    // Guard clauses for invalid layerSelected
    if (isInvalidLayerSelected) return;

    // Create a deep copy of the SVG object
    const svgCopy = JSON.parse(JSON.stringify(props.svg));

    // Remove the layer at the specified index
    svgCopy.children.splice(layerSelected, 1);

    // Shallow copy of colors array
    const colorsCopy = [...props.colors];
    colorsCopy.splice(layerSelected, 1);

    // Update the SVG and colors in the parent component
    updateParentComponent(svgCopy, colorsCopy);

    // Reset layer selection
    props.setLayerSelected((prev) => {
      const { length } = props.colors;
      if (length === 1) return "all";
      if (length === 2 || prev === 0) return 0;
      return prev - 1;
    });
  };

  const layerBorder = (isSelected) =>
    isSelected ? "2px solid #5282FF" : "2px solid #fff";

  return (
    <IonGrid
      style={{
        width: "100%",
        margin: "0px",
        padding: "0px",
        fontFamily: "Clash Grotesk",
        color: "#202020",
      }}
    >
      <IonRow
        style={{
          backgroundColor: "#3D5EB3",
          fontWeight: 500,
          fontSize: "18px",
          color: "#fff",
          padding: "14px",
          display: "flex",
          gap: "11px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={LayerGroupIcon} />
        Layer Panel
      </IonRow>

      {(!currentFile.isPublic || isOwner) && (
        <IonRow
          style={{
            fontSize: "10px",
            display: "flex",
            gap: "4px",
            padding: "0px 4px",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        >
          <IonCol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 4px",
              cursor: isInvalidLayerSelected ? "not-allowed" : "pointer",
              opacity: isInvalidLayerSelected ? 0.5 : 1,
            }}
            onClick={() => handleCopy(props.layerSelected)}
          >
            <img src={CopyIcon} style={{ width: "16px", height: "16px" }} />
            Copy
          </IonCol>
          <IonCol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 4px",
              cursor: isInvalidLayerSelected ? "not-allowed" : "pointer",
              opacity: isInvalidLayerSelected ? 0.5 : 1,
            }}
            onClick={() => handleDelete(props.layerSelected)}
          >
            <img src={DeleteIcon} />
            Delete
          </IonCol>
        </IonRow>
      )}

      <IonRow
        style={{
          padding: "8px 6px",
          background: "rgba(0, 0, 0, 0.04)",
          borderRadius: "8px",
          fontSize: "10px",
          fontWeight: 500,
          margin: "0 6px",
        }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="color-palette">
            {(provided) => (
              <IonGrid {...provided.droppableProps} ref={provided.innerRef}>
                <IonRow>
                  <IonCol>
                    <p style={{ marginBottom: "8px" }}>Full Preview</p>
                    <div
                      style={{
                        border: layerBorder(props.layerSelected === "all"),
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "140px",
                      }}
                      onClick={() => {
                        props.setLayerSelected("all");
                      }}
                    >
                      <LayerPreview
                        svgJoined={props.svgJoined}
                        width="100%"
                        height="82"
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <Divider
                  style={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    backgroundColor: "#D1D1D1",
                  }}
                />
                <p style={{ marginBottom: "8px" }}>Layers</p>
                <div
                  style={{ overflowY: "scroll", height: "calc(100vh - 430px)" }}
                >
                  {props.colors.map((_, index) => (
                    <Draggable
                      key={`color-${index}`}
                      draggableId={`color-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <IonRow
                          key={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <IonCol
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                              marginBottom: "4px",
                              border: layerBorder(
                                props.layerSelected === index
                              ),
                            }}
                            onClick={() => {
                              props.setLayerSelected(index);
                            }}
                          >
                            <img
                              src={DragIcon}
                              style={{ height: "24px", width: "24px" }}
                            />
                            <LayerPreview
                              index={index}
                              svgJoined={props.svgJoined}
                            />
                          </IonCol>
                        </IonRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </IonGrid>
            )}
          </Droppable>
        </DragDropContext>
      </IonRow>
    </IonGrid>
  );
};

export default LayerSelectorDesktop;
