import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilesWithUrls,
  listAllUploadedSVGs,
} from "../../../../lib/firebase/firebase";
import { setFiles } from "../../../../redux/slices/MyFiles";
import FileList from "./FileList";
import { EmptyState } from "./EmptyState";
import { useFileList, fetchUploadedFiles } from "./useFileList";

const MyFiles = () => {
  const dispatch = useDispatch();
  const initialFiles = useSelector((state) => state.files.files);

  const fetchFilesFunc = useCallback(async () => {
    const filesWithUrls = await fetchFilesWithUrls();
    const uploadedFiles = await fetchUploadedFiles(listAllUploadedSVGs);
    const combinedFiles = [...filesWithUrls, ...uploadedFiles];
    combinedFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
    dispatch(setFiles(combinedFiles));
    return combinedFiles;
  }, [dispatch]);

  const { files, visibleCount, lastElementRef } = useFileList(
    initialFiles,
    fetchFilesFunc
  );

  const handleImageLoaded = () => {
    console.log("Image loaded");
  };

  return files.length === 0 ? (
    <EmptyState />
  ) : (
    <FileList
      files={files}
      visibleCount={visibleCount}
      lastElementRef={lastElementRef}
      handleImageLoaded={handleImageLoaded}
    />
  );
};

export default MyFiles;
