import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { ZoomIn, ZoomOut } from "react-feather";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { rgbToHex2 } from "../helpers/helpers";

const MainCanvas = (props) => {
  const [compHeight, setCompHeight] = React.useState("");

  const [initialTouchX, setInitialTouchX] = React.useState(null);
  const [initialTouchY, setInitialTouchY] = React.useState(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    // add a slight delay because the file doesnt always load on the canvas
    setTimeout(() => {
      if (props.file) {
        const img = new Image();

        img.onload = function () {
          const canvas = canvasRef.current;
          if (canvas) {
            const [imgWidth, imgHeight] = fitToContainer(img.width, img.height);
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            canvas
              .getContext("2d")
              .drawImage(
                img,
                0,
                0,
                img.width,
                img.height,
                0,
                0,
                imgWidth,
                imgHeight
              );
          }
        };

        img.src = `${props.file}`;
      }
    }, 300);
  }, [compHeight, props.file]);

  const fitToContainer = (imageWidth, imageHeight) => {
    const canvasScroll = document.getElementById("canvasScroll");
    const containerWidth = canvasScroll.offsetWidth;
    const containerHeight = canvasScroll.offsetHeight;
    var scaledImageHeight = imageHeight;
    var scaledImageWidth = imageWidth;

    let scale = Math.min(
      containerWidth / imageWidth,
      containerHeight / imageHeight
    );

    if (scale < 1) {
      scaledImageHeight = Math.floor(imageHeight * scale);
      scaledImageWidth = Math.floor(imageWidth * scale);
      props.setScale(scale);
    }

    return [scaledImageWidth, scaledImageHeight];
  };

  const getEventLocation = (element, event) => {
    const pos = getElementPosition(element);
    return {
      x: event.pageX - pos.x,
      y: event.pageY - pos.y,
    };
  };

  const getElementPosition = (obj) => {
    var curleft = 0,
      curtop = 0;
    if (obj.offsetParent) {
      do {
        curleft += obj.offsetLeft;
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return { x: curleft, y: curtop };
    }
    return undefined;
  };

  const copyColor = (e) => {
    e.preventDefault();
    // check to see if there was a drag more than 5px
    if (e.type === "touchend") {
      const touch = e.changedTouches[0];

      const deltaX = Math.abs(touch.pageX - initialTouchX);
      const deltaY = Math.abs(touch.pageY - initialTouchY);

      if (deltaX > 5 || deltaY > 5) {
        return;
      }
    }
    const canvas = document.getElementById("canvas");
    const rect = canvas.getBoundingClientRect();
    const context = canvas.getContext("2d");

    // Use the appropriate properties for mouse and touch events
    const clientX =
      e.type === "touchend" ? e.changedTouches[0].clientX : e.clientX;
    const clientY =
      e.type === "touchend" ? e.changedTouches[0].clientY : e.clientY;

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;
    const pixelData = context.getImageData(x, y, 1, 1).data;

    const hex = (
      "000000" + rgbToHex2(pixelData[0], pixelData[1], pixelData[2])
    ).slice(-6);

    // console.log(hex);

    props.setClickedColor(hex);
  };

  const handleMouseMove = (e) => {
    const canvas = document.getElementById("canvas");
    const rect = canvas.getBoundingClientRect();
    const context = canvas.getContext("2d");

    // Use the appropriate properties for mouse events
    const clientX = e.clientX;
    const clientY = e.clientY;

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;
    const pixelData = context.getImageData(x, y, 1, 1).data;

    const hex = (
      "000000" + rgbToHex2(pixelData[0], pixelData[1], pixelData[2])
    ).slice(-6);

    // document.getElementById("status").innerHTML = hex;
    // document.getElementById("color").style.backgroundColor = hex;
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setInitialTouchX(touch.pageX);
    setInitialTouchY(touch.pageY);
  };

  // if windoe inner width is less than 750 then show the scroll bar
  if (window.innerWidth < 750) {
    return (
      <>
        <div
          id="canvasScroll"
          style={{
            width: "100vw",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TransformWrapper>
            <TransformComponent wrapperStyle={{ overflow: "visible" }}>
              <canvas
                ref={canvasRef}
                id="canvas"
                width="10"
                height="10"
                onTouchStart={handleTouchStart}
                onTouchEnd={copyColor}
                onMouseUp={copyColor}
                onMouseMove={(e) => {
                  handleMouseMove(e);
                }}
              >
                {" "}
              </canvas>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "calc(100% - 40px)",
          cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewbox="0 0 48 48" ><path stroke-width="1" style="stroke: white; fill: black;" d="M17.542 13.147l-7.455-6.667 1.334-1.49c.822.736 2.087.666 2.822-.159l3.503-3.831c.593-.663 1.414-1 2.238-1 1.666 0 3.016 1.358 3.016 2.996 0 .723-.271 1.435-.78 2.005l-3.503 3.83c-.735.824-.666 2.087.158 2.825l-1.333 1.491zm-4.314-1.175l-7.791 8.65c-.314.352-1.322.323-1.853.557.172-.554.048-1.538.362-1.89l7.791-8.651-1.491-1.333-7.9 8.794c-1.277 1.423-.171 2.261-1.149 4.052-.135.244-.197.48-.197.698 0 .661.54 1.151 1.141 1.141.241 0 .492-.079.724-.256 1.733-1.332 2.644-.184 3.954-1.647l7.9-8.792-1.491-1.333z"/></svg>') 0 24, auto`,
        }}
      >
        <div
          id="canvasScroll"
          style={{
            overflow: "hidden",
            backgroundColor: "#f0f0f0",
            height: "100%",
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TransformWrapper>
            {({ zoomIn, zoomOut, setTransform, state }) => (
              <>
                <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                  <canvas
                    ref={canvasRef}
                    id="canvas"
                    width="10"
                    height="10"
                    onTouchStart={handleTouchStart}
                    onTouchEnd={copyColor}
                    onMouseUp={copyColor}
                    onMouseMove={(e) => {
                      handleMouseMove(e);
                    }}
                    style={{ display: "block", margin: "auto" }}
                  >
                    {" "}
                  </canvas>
                </TransformComponent>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    hidden
                    id="zoomOutButton"
                    onClick={() => {
                      // const canvas = document.getElementById("canvas");
                      // const rect = canvas.getBoundingClientRect();
                      // const centerX = rect.width / 2;
                      // const centerY = rect.height / 2;
                      // setTransform(0, 0, state.scale / 1.1);
                      // call zoomin
                      // zoomIn();
                      // center the image
                      const canvas = document.getElementById("canvas");
                      const rect = canvas.getBoundingClientRect();
                      const centerX = rect.width / 2;
                      const centerY = rect.height / 2;
                      console.log(state);

                      // get the current location from the state
                      const currentX = state.positionX;
                      const currentY = state.positionY;
                      // set the new location

                      setTransform(currentX, currentY, state.scale / 1.1);
                    }}
                    type="text"
                    style={{ marginTop: "5px" }}
                  >
                    <ZoomOut size={16} />
                  </Button>
                  <Button
                    hidden
                    id="zoomInButton"
                    onClick={() => {
                      const canvas = document.getElementById("canvas");
                      const rect = canvas.getBoundingClientRect();
                      const centerX = rect.width / 2;
                      const centerY = rect.height / 2;
                      console.log(state);
                      // get the current location from the state
                      const currentX = state.positionX;
                      const currentY = state.positionY;
                      // set the new location

                      setTransform(currentX, currentY, state.scale * 1.1);
                    }}
                    type="text"
                    style={{ marginTop: "5px" }}
                  >
                    <ZoomIn size={16} />
                  </Button>
                </div>
              </>
            )}
          </TransformWrapper>
        </div>
      </div>
    );
  }
};

export default MainCanvas;
