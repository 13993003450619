import { LoadingOutlined } from "@ant-design/icons"; // Import the loading icon
import { IonLoading } from "@ionic/react";

import {
  faBoltLightning,
  faCloudArrowUp,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPaperclipVertical,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Row, notification } from "antd"; // Added Upload and Modal to the import from antd
import axios from "axios";
import Filter from "bad-words"; // Import Filter from bad-words package
import { useContext, useEffect, useState } from "react";
import leftActiveArrowIcon from "../../../assets/left-active-arrow.svg";
import leftArrowIcon from "../../../assets/left-arrow.svg";
import svgTraceIcon from "../../../assets/svgtrace.svg";
import AppContext from "../../../contexts/AppContext";
import { useAuth } from "../../../contexts/AuthContext";
import "./DallePromptForm.css"; // Replace with your actual CSS file path
import GetCredits from "./GetCredits/GetCredits";

const { useNotification } = notification;

const tabs = [
  { title: "All", name: "carousel", icon: faRectangleVerticalHistory },
  { title: "Uploaded", name: "uploaded", icon: faCloudArrowUp },
  { title: "Generated", name: "myfiles", icon: faBoltLightning },
  { title: "Resize", name: "resize", icon: faExpand },
];

const DallePromptForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [getCreditModal, setGetCreditModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "✨Generating your design✨ Please wait..."
  );
  // const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const { setImage64, setImageSource } = useContext(AppContext);
  const { setImageSEO } = useContext(AppContext);
  const { setImagePrompt } = useContext(AppContext);
  const [api, contextHolder] = useNotification();
  const { user } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [progress, setProgress] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const defaultSuffixIcon = leftArrowIcon; // Assuming leftArrowIcon is your default
  const focusedSuffixIcon = leftActiveArrowIcon;
  const typingSuffixIcon = leftActiveArrowIcon;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    if (loading) {
      setProgress(0); // Reset progress when loading starts
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 5;
          console.log(`Current progress: ${nextProgress}`); // Debug log
          if (nextProgress > 95) {
            clearInterval(intervalId);
          }
          return nextProgress;
        });
      }, 1500); // Update progress every 1.5 seconds
    }

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
      console.log("Interval cleared"); // Debug log
    };
  }, [loading]);

  const filter = new Filter();

  const checkPromptForProfanity = async (prompt) => {
    if (filter.isProfane(prompt)) {
      console.log("Profanity detected in prompt. Please revise.");
    }
    return true;
  };

  const openNotification = (message, type) => {
    api.open({
      message,
      duration: 3,
      type,
      placement: "top",
    });
  };

  const generateImage = async (prompt) => {
    setLoading(true);
    // setIsModalVisible(true); // Show modal when generation starts

    // Check if user has credits
    // if (props.limit <= 0) {
    //   setLoading(false);
    //   // setIsModalVisible(false);
    //   setGetCreditModal(true); // Show the GetCredits modal if no credits
    //   openNotification(
    //     "You are out of credits. Please buy more to continue.",
    //     "info"
    //   );
    //   return;
    // }

    if (!(await checkPromptForProfanity(prompt))) {
      setLoading(false);
      // setIsModalVisible(false); // Hide modal if there's an error
      openNotification(
        "Profanity detected in prompt. Please revise before sending.",
        "error"
      );
      return;
    }
    try {
      const accessToken = await user.getIdToken();
      const resp = await axios.post(
        process.env.REACT_APP_GENERATE_IMAGE_URL,
        { prompt },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Description: ", resp.data.seoDescription);
      console.log("Title: ", resp.data.title);
      setImageSEO({
        title: resp.data.title,
        description: resp.data.seoDescription,
      });
      setProgress(100);
      setImage64("data:image/png;base64," + resp.data.base64);
      setImageSource("generated"); // Set image source to "generated"
      openNotification("Image generated successfully", "success");
    } catch (error) {
      console.error(error);
      openNotification(
        "Something went wrong while generating the image.",
        "error"
      );
    }
    setLoading(false);
    setProgress(0); // Reset progress
    // setLoadingMessage(`Generating your design! Please wait...${progress}%`);
    // setIsModalVisible(false); // Hide modal when generation is complete
  };

  const onFinish = (values) => {
    const { subject } = values;

    setImagePrompt(subject);
    const prompt = `Create a flat, vector-style image on a solid background. The design should feature a ${subject}, keep it simple and clean for easy cutting. The image should have a clear and solid background, without any additional elements. Use the SVG image previews off etsy as a reference.`;

    generateImage(subject);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleInput = (e) => {
    setIsTyping(e.target.value.length > 0);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsTyping(false);
  };

  const inputClassName = `input-field ${isFocused && "focused"} ${
    isTyping && "typing"
  }`;
  const prefixImgClassName = `prefix-img ${isFocused && "prefix-img-focused"} ${
    isTyping && "prefix-img-typing"
  }`;
  // Determine which icon to use based on the state
  let suffixIconSrc = defaultSuffixIcon;
  if (isFocused) {
    suffixIconSrc = focusedSuffixIcon;
  }
  if (isTyping) {
    suffixIconSrc = typingSuffixIcon;
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (loadEvent) => {
      const img = new Image();
      img.src = loadEvent.target.result;
      img.onload = async () => {
        // Create canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions
        const maxDimension = 800; // Adjust this value as needed
        canvas.width =
          img.width > img.height
            ? maxDimension
            : (img.width / img.height) * maxDimension;
        canvas.height =
          img.height > img.width
            ? maxDimension
            : (img.height / img.width) * maxDimension;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert canvas to base64
        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // Adjust compression quality as needed
        const base64Part = compressedBase64.split("base64,")[1];

        console.log("Compressed Base64 Image:", compressedBase64); // Debug log for compressed base64 image

        try {
          const accessToken = await user.getIdToken();
          const response = await axios.post(
            process.env.REACT_APP_PROCESS_IMAGE_URL,
            { base64Image: base64Part },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          const { title, seoDescription, base64 } = response.data;

          console.log("Image processed successfully", JSON.stringify(response));
          console.log("Description:", seoDescription);
          console.log("Title:", title);

          setImageSEO({ title, description: seoDescription });
          setProgress(100);
          setImage64(`data:image/png;base64,${base64}`);
          setImageSource("generated");
          openNotification("Image processed successfully", "success");
        } catch (error) {
          console.error(error);
          openNotification(
            "Something went wrong while processing the image.",
            "error"
          );
        }

        setLoading(false);
        setProgress(0); // Reset progress
        event.target.value = null; // Reset the file input value
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      {contextHolder}

      <IonLoading
        id="generateLoading"
        isOpen={loading}
        message={loadingMessage}
      />
      <div
        style={{
          background:
            "url(/assets/generatesource.png) center  / cover no-repeat", // Centered and cover the container
          padding: "33px",
          borderRadius: "10px",
          marginLeft: "10px",
          marginRight: "10px",
          // marginTop: "20px",
          position: "relative",
          // width: "100%", // Set width to 100%
          position: "relative", // Ensure the container is positioned relatively
          height: "100%",
          minWidth: "100%",
        }}
      >
        <Row align="middle" justify="center">
          <Col
            span={24}
            style={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="generate-image-title">
                {loading ? "Generating..." : "Generate an Image"}
              </h1>
              <Button
                type="primary"
                // make the button small
                size="small"
                onClick={() => {
                  /* Define your button action here */
                }}
                style={{
                  borderRadius: "6px",
                  background:
                    "linear-gradient(105deg, #00E9FF -71.56%, #FD90FF 131.26%)",

                  color: "#000",
                }}
              >
                AI
              </Button>
            </div>
          </Col>

          <Col span={24}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontStyle: "italic",
              }}
            >
              Upload an inspo image or describe your design.
            </p>{" "}
            {/* Added description */}
          </Col>
          <Col span={16}>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="subject"
                rules={[
                  { required: true, message: "Please describe your design." },
                ]}
                style={{ width: "100%", fontWeight: "400" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Input
                    loading={loading.toString()}
                    disabled={loading}
                    size="large"
                    placeholder="Example: A cat with a bowtie"
                    className={inputClassName}
                    onFocus={handleFocus}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    prefix={
                      <div
                        className={prefixImgClassName}
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <FontAwesomeIcon icon={faPaperclipVertical} />
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept="image/png, image/jpeg, image/webp"
                        />
                      </div>
                    }
                    suffix={
                      <Button
                        disabled={loading}
                        htmlType="submit"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "transparent", // Set button background to transparent
                        }}
                        icon={
                          loading ? (
                            <LoadingOutlined style={{ color: "#68b1f0" }} />
                          ) : (
                            <Row>
                              <img
                                src={suffixIconSrc}
                                alt="leftArrowIcon"
                                style={{ padding: "10px" }}
                              />
                            </Row>
                          )
                        }
                      />
                    }
                  />
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <div className="svg-trace">
          <img src={svgTraceIcon} alt="svgTraceIcon" />
          <h3>Commercial Use</h3>
        </div>

        {/* <div className="tabs-list">
          {tabs.map((tab) => (
            <div className="tabs" key={tab.name}>
              <FontAwesomeIcon
                icon={tab.icon}
                className={`${currentTab == tab.name ? "selected-icon" : "non-selected-icon"
                  }`}
                onClick={() => dispatch(setCurrentTab(tab.name))}
              />
              <p className={`${currentTab == tab.name && "active"}`}>
                {tab.title}
              </p>
            </div>
          ))}
        </div> */}
      </div>
      {getCreditModal && <GetCredits setGetCreditModal={setGetCreditModal} />}
    </>
  );
};

export default DallePromptForm;
