import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentTab } from "../../../../redux/slices/CurrentTab";
import Card from "../Card";
import "./Carousel.css";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import UploadCard from "../UploadCard";
import leftArrow from "../../../../assets/left-arrow-all.svg";
import noDataImage from "../../../../assets/no_data_image.svg";
import groupStars from "../../../../assets/group_stars.svg";

const Carousel1 = ({ data, isUpload }) => {
  const carouselRef = useRef();
  const dispatch = useDispatch();
  const [showPrevious, setShowPrevious] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false); // Add a state to control auto-scrolling
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (data && data.data.length > 0) {
      const handleScroll = () => {
        const container = carouselRef.current;
        const containerWidth = container.clientWidth;
        const scrollWidth = container.scrollWidth;
        const scrollLeft = container.scrollLeft;
        setShowPrevious(scrollLeft > 0);
        setShowNext(scrollLeft + containerWidth < scrollWidth);
      };

      carouselRef.current.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial call to set buttons visibility

      let autoScroll;
      if (autoScrollEnabled) {
        // Set up the interval for automatic scrolling only if enabled
        autoScroll = setInterval(() => {
          if (showNext) {
            next(); // Scroll to the next set of items
          }
        }, 3000); // Change 3000 to whatever interval you prefer in milliseconds
      }

      return () => {
        const container = carouselRef.current;
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
        if (autoScroll) {
          clearInterval(autoScroll); // Clear the interval when the component unmounts
        }
      };
    } else {
      console.log("No data available for carousel");
    }
  }, [data, showNext, autoScrollEnabled]); // Depend on data to re-run this effect

  const next = () => {
    const scrollDistance = carouselRef.current.clientWidth;
    carouselRef.current.scrollTo({
      left: carouselRef.current.scrollLeft + scrollDistance,
      behavior: "smooth",
    });
  };

  const previous = () => {
    const scrollDistance = carouselRef.current.clientWidth;
    carouselRef.current.scrollTo({
      left: carouselRef.current.scrollLeft - scrollDistance,
      behavior: "smooth",
    });
  };

  const onMouseDown = (e) => {
    e.preventDefault(); // Prevent default behavior like text selection or dragging images
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault(); // Continue to prevent default behavior while moving
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the speed as necessary
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const slider = carouselRef.current;
    slider.addEventListener("mousedown", onMouseDown);
    slider.addEventListener("mousemove", onMouseMove);
    slider.addEventListener("mouseup", onMouseUp);
    slider.addEventListener("mouseleave", onMouseUp);

    return () => {
      slider.removeEventListener("mousedown", onMouseDown);
      slider.removeEventListener("mousemove", onMouseMove);
      slider.removeEventListener("mouseup", onMouseUp);
      slider.removeEventListener("mouseleave", onMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div
      style={{
        width: "96vw",
        position: "relative",
      }}
    >
      <div className="name-see-all">
        <h3>
          {data.data.length <= 0 && data.title === "Generated" && (
            <img src={groupStars} alt="group stars" />
          )}
          {data.title}
        </h3>
        <button onClick={() => dispatch(setCurrentTab(data.link))}>
          <span className="viewall-text">View All</span>
          <img src={leftArrow} alt="arrow" />
        </button>
      </div>
      <div className="cardsHolder" ref={carouselRef}>
        {data.data.length <= 0 ? (
          <div
            className={`no-data-container `}
            style={{
              // center
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img src={noDataImage} alt="no data" />
            <p className="no-text">No Image found</p>
          </div>
        ) : (
          <></>
        )}

        {data.data.slice(0, 15).map((file) => (
          <Card file={file} key={file.id} />
        ))}
        {data.data.length > 15 && (
          <div className="showmore">
            <div
              className="parent"
              onClick={() => dispatch(setCurrentTab(data.link))}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
              <p>Show more...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel1;
