import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: [],
  showEditor: false,
  currentFile: "",
};

export const myFilesSlice = createSlice({
  name: "myFileSlice",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    updateFile: (state, action) => {
      const { url, filePath } = action.payload;
      const fileToUpdate = state.files.find(
        (file) => file.filePath === filePath
      );
      if (fileToUpdate) {
        fileToUpdate.url = url;
      }
    },
    addNewlyGeneratedFile: (state, action) => {
      state.files.unshift(action.payload);
    },
    deleteFileInRedux: (state, action) => {
      console.log("here we are in the delete in redux");
      state.files = state.files.filter(
        (file) => file.filePath !== action.payload
      );
    },
    updateFileIsPublicStatus: (state, action) => {
      const { filePath, isPublic } = action.payload;
      const fileToUpdate = state.files.find(
        (file) => file.filePath === filePath
      );
      if (fileToUpdate) {
        fileToUpdate.isPublic = isPublic;
      }
    },
    setShowEditor: (state, action) => {
      const { showEditor, currentFile } = action.payload;
      state.showEditor = showEditor;
      state.currentFile = currentFile;
    },
  },
});

export const {
  setFiles,
  setShowEditor,
  updateFile,
  deleteFileInRedux,
  updateFileIsPublicStatus,
  addNewlyGeneratedFile,
} = myFilesSlice.actions;
export default myFilesSlice.reducer;
