import { IonCol, IonGrid, IonRow } from "@ionic/react";
import BackArrowIcon from "../../../assets/SvgEditor/BackArrow.svg";
import Close from "./Close/Close";
import DesktopToolbar from "./DesktopToolBar";
import SaveIcon from "../../../assets/SvgEditor/Save.svg";
import ExportIcon from "../../../assets/SvgEditor/Export.svg";
import useSaveSVG from "../../../hooks/useSaveSvg";
import ExportFile from "./ExportFile";
import { useAuth } from "../../../contexts/AuthContext";

const DesktopTopBar = ({
  toolSelected,
  setToolSelected,
  layerSelected,
  setIsCropVisible,
  svg,
  updateSvg,
  undo,
  redo,
  redoActions,
  undoActions,
  colors,
  setColors,
  svgJoined,
  changesMade,
  currentFile,
  setChangesMade,
  undoAll,
  eraserRadius,
  setEraserRadius,
  vaccumRadius,
  setVaccumRadius,
}) => {
  const { user } = useAuth();
  const { saveSVG, loading: savingInProgress } = useSaveSVG(
    svgJoined,
    currentFile
  );
  const isOwner = user.uid === currentFile.ownerUid;

  return (
    <IonGrid
      style={{
        backgroundColor: "#F4F4F4",
        width: "100%",
        padding: 0,
        color: "#202020",
        fontFamily: "Clash Grotesk",
      }}
    >
      <IonRow>
        <IonCol size="auto" style={{ padding: 0, width: "188px" }}>
          <Close
            changesMade={changesMade}
            svgJoined={svgJoined}
            currentFile={currentFile}
            styles={{
              width: "188px",
              height: "100%",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
              background: "transparent",
            }}
          >
            <img
              src={BackArrowIcon}
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontSize: "14px" }}>Back to files</span>
          </Close>
        </IonCol>
        <IonCol style={{ marginLeft: "25px" }}>
          <DesktopToolbar
            toolSelected={toolSelected}
            setToolSelected={setToolSelected}
            layerSelected={layerSelected}
            setIsCropVisible={setIsCropVisible}
            svg={svg}
            updateSvg={updateSvg}
            undo={undo}
            redo={redo}
            redoActions={redoActions}
            undoActions={undoActions}
            colors={colors}
            setColors={setColors}
            eraserRadius={eraserRadius}
            setEraserRadius={setEraserRadius}
            vaccumRadius={vaccumRadius}
            setVaccumRadius={setVaccumRadius}
            currentFile={currentFile}
          />
        </IonCol>
        <IonCol
          style={{
            fontSize: "12px",
            display: "flex",
            gap: "6px",
            justifyContent: "flex-end",
            marginRight: "14px",
            padding: "10px 0",
          }}
        >
          {!currentFile.isPublic ||
            (isOwner && (
              <button
                style={{
                  fontWeight: 600,
                  fontFamily: "Clash Grotesk",
                  background: "transparent",
                }}
                onClick={undoAll}
              >
                Reset all Changes
              </button>
            ))}
          {!currentFile.isPublic ||
            (isOwner && (
              <button
                style={{
                  fontWeight: 600,
                  fontFamily: "Clash Grotesk",
                  color: "#F453C4",
                  background: "#fff",
                  border: "1px solid #F453C4",
                  padding: "6px 12px",
                  borderRadius: "4px",
                  opacity: (!changesMade || savingInProgress) && 0.5,
                }}
                disabled={!changesMade || savingInProgress}
                onClick={async () => {
                  await saveSVG();
                  setChangesMade(false);
                }}
              >
                <img src={SaveIcon} alt="save" style={{ marginRight: "4px" }} />
                Save
              </button>
            ))}
          <ExportFile
            svgJoined={svgJoined}
            buttonType="primary"
            fileName={currentFile?.name}
          >
            <img src={ExportIcon} alt="export" style={{ marginRight: "4px" }} />
            Export
          </ExportFile>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DesktopTopBar;
