import { Button } from "antd";
import { useCallback, useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import ColorAnalyzer from "./ColorAnalyzer"; // Import ColorAnalyzer component

const AutoUploadImage = (props) => {
  const { image64 } = useContext(AppContext);

  const processImage = useCallback(() => {
    let targetImg = image64;
    if (targetImg) {
      props.setFile(targetImg);
    } else {
      const svgXml = new XMLSerializer().serializeToString(
        document.getElementById("svgContainer")
      );
      targetImg =
        "data:image/svg+xml;base64," +
        btoa(unescape(encodeURIComponent(svgXml)));
      props.setFile(targetImg);
    }

    const img = new Image();
    img.onload = () => {
      // Add your image processing logic here
      const dimensions = [
        img.width > 400 ? img.width : img.width * 2,
        img.height > 400 ? img.height : img.height * 2,
      ];
      props.setOriginalDimensions(dimensions);

      if (img.width * img.height > 2073600) props.setLargeImageWarning(true);
      else props.setLargeImageWarning(false);

      props.setCrop({
        unit: "px",
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      });

      if (props.setImageDimensions)
        props.setImageDimensions([img.width, img.height]);
      if (props.setDespeckle) props.setDespeckle(img.width * 0.01);
      // You can display the image in the UI here if needed
      // For example, update a state with the image URL

      // Correctly use the static method from ColorAnalyzer
      ColorAnalyzer.processImage(img.src)
        .then((hexColors) => {
          const newColors = hexColors.map((color) => [color.slice(1), true]);
          props.setColors(newColors);
        })
        .catch((error) => {
          console.error("Error processing image with ColorAnalyzer:", error);
        });
    };

    img.onerror = (event) => {
      console.error("There was a problem loading the image.", event);
      // Handle the error as needed
    };
    img.src = `${targetImg}`;
  }, [image64, props]);

  return (
    <Button
      // id is NewConvertUpload
      id="AutoConvertUpload"
      hidden
      onClick={processImage}
    >
      Process Image
    </Button>
  );
};

export default AutoUploadImage;
