import {
  IonContent,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { Button, Col, Row } from "antd";
import { Crop } from "react-feather";
import AdvancedSettings from "./AdvancedSettings";
import ColorPreview from "./ColorPreview";
import CropPng from "./CropPng";
import MainCanvas from "./MainCanvas";
import Submit from "./Submit";
import { useEffect } from "react";

const ConverterMobile = ({
  screenSize,
  crop,
  setCrop,
  setFile,
  file,
  scale,
  setScale,
  setClickedColor,
  setImageDimensions,
  originalDimensions,
  largeImageWarning,
  downloadSVGLink,
  isLoading,
  setIsCropVisible,
  isCropVisible,
  smoothness,
  setSmoothness,
  despeckle,
  setDespeckle,
  simplifyCurves,
  setSimplifyCurves,
  imageDimensions,
  ai,
  setAi,
  colors,
  setColors,
  setSvgPreview,
  setPreviewReady,
  setSvg,
  setShareLink,
  setIsModalVisible,
  setIsLoading,
  setDownloadSVGLink,
  setLoadingConverter,
}) => {
  useEffect(() => setLoadingConverter(false), []);

  return (
    <>
      <div id="color" hidden></div>
      <div id="status" hidden></div>
      <IonContent fullscreen={true}>
        <IonGrid id="middleBox" style={{ height: "100%", padding: "0px" }}>
          <IonRow style={{ height: "100%", backgroundColor: "#f0f0f0" }}>
            <MainCanvas
              screenSize={screenSize}
              crop={crop}
              setFile={setFile}
              file={file}
              scale={scale}
              setScale={setScale}
              setClickedColor={setClickedColor}
            />
            <CropPng
              setScale={setScale}
              setImageDimensions={setImageDimensions}
              originalDimensions={originalDimensions}
              cropGlobal={crop}
              setCropGlobal={setCrop}
              largeImageWarning={largeImageWarning}
              file={file}
              isModalVisible={isCropVisible}
              setIsModalVisible={setIsCropVisible}
              downloadSVGLink={downloadSVGLink}
              isLoading={isLoading}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar style={{ color: "#f0f0f0" }}>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "100%",
            }}
          >
            <AdvancedSettings
              smoothness={smoothness}
              despeckle={despeckle}
              simplifyCurves={simplifyCurves}
              setSmoothness={setSmoothness}
              setDespeckle={setDespeckle}
              setSimplifyCurves={setSimplifyCurves}
              imageDimensions={imageDimensions}
              ai={ai}
              setAi={setAi}
            />
            <Col style={{ paddingRight: "10px" }}>
              <Button
                type="text"
                onClick={() => {
                  setIsCropVisible(true);
                }}
                style={{ width: "100%" }}
              >
                <Row justify="center">
                  <Crop size={14} style={{}} />
                </Row>
                <Row
                  justify="center"
                  style={{ fontSize: "10px", maxWidth: "20px" }}
                >
                  Crop
                </Row>
              </Button>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "100%",
            }}
          >
            <Col
              justify="space-between"
              style={{
                maxWidth: `calc(95%)`,
                overflowX: "auto",
                marginTop: "-15px",
              }}
            >
              <IonRow style={{ minWidth: `${(colors.length + 1) * 50}px` }}>
                <ColorPreview colors={colors} setColors={setColors} />
              </IonRow>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "40px",
              width: "100%",
              marginTop: "-5px",
            }}
          >
            <Submit
              setFile={setFile}
              setSvgPreview={setSvgPreview}
              setPreviewReady={setPreviewReady}
              setSvg={setSvg}
              originalDimensions={originalDimensions}
              crop={crop}
              despeckle={despeckle}
              simplifyCurves={simplifyCurves}
              smoothness={smoothness}
              colors={colors}
              file={file}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setDownloadSVGLink={setDownloadSVGLink}
              setShareLink={setShareLink}
              setIsModalVisible={setIsModalVisible}
              ai={ai}
            />
          </Row>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ConverterMobile;
