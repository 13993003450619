import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadedFiles: [],
};

export const uploadedSlice = createSlice({
  name: "uploadedSlice",
  initialState,
  reducers: {
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload;
    },
    addNewlyUploadedFile: (state, action) => {
      state.uploadedFiles.unshift(action.payload);
    },
    updateUploadedFileInRedux: (state, action) => {
      const { url, filePath } = action.payload;
      const fileToUpdate = state.uploadedFiles.find(
        (file) => file.filePath === filePath
      );
      if (fileToUpdate) fileToUpdate.url = url;
    },
    deleteUploadedFileInRedux: (state, action) => {
      state.uploadedFiles = state.uploadedFiles.filter(
        (file) => file.filePath !== action.payload
      );
    },
  },
});

export const {
  setUploadedFiles,
  addNewlyUploadedFile,
  updateUploadedFileInRedux,
  deleteUploadedFileInRedux,
} = uploadedSlice.actions;
export default uploadedSlice.reducer;
