import { IonButton, IonSpinner } from "@ionic/react";

const EmailVerification = ({ loading, onResendEmail }) => (
  <div className="container">
    <div className="ion-padding">
      <h2>Verify your email</h2>
      <p>Please verify your email then refresh the page.</p>
      <IonButton
        className="submit-button-2"
        fill="clear"
        onClick={onResendEmail}
        disabled={loading}
      >
        {loading ? <IonSpinner name="lines" /> : "Resend email"}
      </IonButton>
      <IonButton
        className="submit-button"
        onClick={() => window.location.reload()}
        disabled={loading}
      >
        {loading ? <IonSpinner name="lines" /> : "Refresh page"}
      </IonButton>
    </div>
  </div>
);

export default EmailVerification;
