import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilesWithUrls,
  uploadedSVGsInStorage,
} from "../../../lib/firebase/firebase";
import { setFiles } from "../../../redux/slices/MyFiles";
import { setUploadedFiles } from "../../../redux/slices/UploadedFiles";
import Carousel1 from "./Carousel/Carousel";
import "./styles.css";

const All = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files.files);
  const { uploadedFiles } = useSelector((state) => state.uploadedFiles);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      getUploadedFiles();
    }
    if (files.length === 0) {
      getFiles();
    }
  }, []);

  const getUploadedFiles = async () => {
    try {
      const uploadedFiles = await uploadedSVGsInStorage();
      dispatch(setUploadedFiles(uploadedFiles));
    } catch (error) {
      console.error("error getting files: ", error);
    }
  };

  const getFiles = async () => {
    try {
      const filesWithUrls = await fetchFilesWithUrls();
      dispatch(setFiles(filesWithUrls));
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const carouselData = [
    {
      title: "Uploaded",
      link: "uploaded",
      data: uploadedFiles,
      isUpload: true,
    },
    { title: "Generated", link: "myfiles", data: files, isUpload: false },
  ];

  return (
    <div>
      {carouselData.map((data) => (
        <Carousel1 key={data.title} data={data} isUpload={data.isUpload} />
      ))}
    </div>
  );
};

export default All;
