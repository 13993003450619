import Card from "../Card";
import "./styles.css";

const FileList = ({
  files,
  visibleCount,
  lastElementRef,
  handleImageLoaded,
}) => {
  return (
    <div className="cards-container">
      <div className="inner-container">
        {files.slice(0, visibleCount).map((file, index) => {
          const isLastElement = index === visibleCount - 1;
          return (
            <div
              ref={isLastElement ? lastElementRef : null}
              key={file.filePath}
            >
              <Card
                file={file}
                index={index}
                onImageLoaded={handleImageLoaded}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FileList;
