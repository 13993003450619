import { IonButton, IonContent, IonPage, IonSpinner } from "@ionic/react";
import { useState } from "react";
import preview from "../../assets/preview_pro.png";
import WebNavbar from "../../components/WebNavbar";
import { useAuth } from "../../contexts/AuthContext";
import { verifyEmail } from "../../lib/firebase/firebase";
import { createCheckoutSession } from "../../lib/stripe/createCheckoutSession";
import Benefits from "./Benefits";
import "./Checkout.css";
import EmailVerification from "./EmailVerification";
import PlanSelection from "./PlanSelection";
import { PLANS } from "./constants";

const Checkout = () => {
  const { user, userIsPremium } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("yearly");

  const handleUpgrade = async (subscriptionType) => {
    setLoading(true);
    await createCheckoutSession(user.uid, subscriptionType);
    setLoading(false);
  };

  const handleResendEmail = async () => {
    setLoading(true);
    await verifyEmail();
    setLoading(false);
  };

  return (
    <IonPage>
      <WebNavbar />
      <IonContent className="background">
        {!user.emailVerified && (
          <EmailVerification
            loading={loading}
            onResendEmail={handleResendEmail}
          />
        )}
        {user.emailVerified && !userIsPremium && (
          <div className="checkout-container">
            <div className="ion-padding">
              <div className="top">
                <h3>Try SvgTrace Pro</h3>
              </div>
              <br />
              <img src={preview} alt="logo" width="100%" />
              <div className="sub_total">
                <div>
                  {/* <h5>Here is what you get with SvgTrace Pro</h5> */}
                  <Benefits />
                </div>
              </div>
              <PlanSelection
                selectedPlan={selectedPlan}
                onPlanSelect={setSelectedPlan}
              />
              <IonButton
                className="submit-button"
                expand="block"
                onClick={() => {
                  const plan = PLANS.find((p) => p.type === selectedPlan);
                  handleUpgrade(plan.subscriptionType);
                }}
                disabled={loading}
              >
                {loading ? <IonSpinner name="lines" /> : "Check out"}
              </IonButton>
              <p className="prompt_text">
                iOS and web versions are sold separately.
              </p>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Checkout;
