import { useDispatch, useSelector } from "react-redux";
import { uploadedSVGsInStorage } from "../../../../lib/firebase/firebase";
import { setUploadedFiles } from "../../../../redux/slices/UploadedFiles";
import { EmptyState } from "./EmptyState";
import FileList from "./FileList";
import { fetchUploadedFiles, useFileList } from "./useFileList";
import { useCallback } from "react";

const Uploaded = () => {
  const dispatch = useDispatch();
  const initialFiles = useSelector(
    (state) => state.uploadedFiles.uploadedFiles
  );

  const fetchFilesFunc = useCallback(async () => {
    const uploadedFiles = await fetchUploadedFiles(uploadedSVGsInStorage);
    dispatch(setUploadedFiles(uploadedFiles));
    return uploadedFiles;
  }, [dispatch]);

  const { files, visibleCount, lastElementRef } = useFileList(
    initialFiles,
    fetchFilesFunc
  );

  const handleImageLoaded = () => {
    console.log("Image loaded");
  };

  return files.length === 0 ? (
    <EmptyState />
  ) : (
    <FileList
      files={files}
      visibleCount={visibleCount}
      lastElementRef={lastElementRef}
      handleImageLoaded={handleImageLoaded}
    />
  );
};

export default Uploaded;
