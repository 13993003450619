import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./CropImage.css";
import { useDebounceEffect } from "./useDebounceEffect";
import BackArrowIcon from "../../../assets/SvgEditor/BackArrow.svg";
import TickIcon from "../../../assets/Converter/Tick.svg";

const CropPng = (props) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [modalHeight, setModalHeight] = useState("50%");
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [completedCrop, setCompletedCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  function onImageLoad(e) {
    imgRef.current = e.currentTarget;
    console.log(props.originalDimensions);
    const [width, height] = fitToModal(
      props.originalDimensions[0],
      props.originalDimensions[1]
    );
    setModalHeight(`${height + 56 * 1.6}px`);
    const image = document.getElementById("cropRefImage");
    image.width = width;
    image.height = height;

    const imageBackground = document.getElementById("imageBackground");
    imageBackground.style.width = `${width + 10}px`;
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const fitToContainer = (imageWidth, imageHeight) => {
    const canvasScroll = document.getElementById("canvasScroll");
    const containerWidth = canvasScroll.offsetWidth;
    const containerHeight = canvasScroll.offsetHeight;

    var scaledImageHeight = imageHeight;
    var scaledImageWidth = imageWidth;

    let scale = Math.min(
      containerWidth / imageWidth,
      containerHeight / imageHeight
    );

    if (scale < 1) {
      console.log("true");
      scaledImageHeight = Math.floor(imageHeight * scale);
      scaledImageWidth = Math.floor(imageWidth * scale);
      props.setScale(scale);
      console.log("the scale is: ", scale);
    }

    return [scaledImageWidth, scaledImageHeight];
  };

  const fitToModal = (imageWidth, imageHeight) => {
    // get the width of the example-modal element. get it by id

    // get element by id
    const exampleModal = document.getElementById("contentid");
    console.log("exampleModal: ", exampleModal.offsetWidth);
    const containerWidth = exampleModal.offsetWidth - 50;
    // the offset width appears toi be the totalwidth of yhe screen not the modal

    // const containerWidth = window.innerWidth;
    const containerHeight = (2 * window.innerHeight) / 3;

    var scaledImageHeight = imageHeight;
    var scaledImageWidth = imageWidth;

    let scale = Math.min(
      containerWidth / imageWidth,
      containerHeight / imageHeight
    );

    if (scale < 1) {
      console.log("true");
      scaledImageHeight = Math.floor(imageHeight * scale);
      scaledImageWidth = Math.floor(imageWidth * scale);
      props.setScale(scale);
      console.log("the scale is: ", scale);
    }

    return [scaledImageWidth, scaledImageHeight];
  };

  return (
    <div className="test">
      <IonModal
        id="example-modal"
        style={{
          "--height": modalHeight,
          "--width": "1164px",
          "--max-width": "95%",
          "--border-radius": "8px",
        }}
        isOpen={props.isModalVisible}
        onDidDismiss={() => props.setIsModalVisible(false)}
      >
        <IonToolbar
          style={{
            "--background": "#fff",
            height: "65px",
            display: "flex",
          }}
        >
          <IonTitle style={{ fontSize: "22px", color: "#2A2A2A", padding: 0 }}>
            Crop Image
          </IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => props.setIsModalVisible(false)}>
              <IonIcon icon={BackArrowIcon} />
            </IonButton>
          </IonButtons>
          <IonButtons
            slot="end"
            style={{
              background: "#F453C4",
              borderRadius: "4px",
              color: "#F8F8F8",
              marginRight: "20px",
            }}
          >
            <IonButton
              style={{
                fontSize: "12px",
                fontWeight: "600",
                "--padding-start": "12px",
                "--padding-end": "12px",
              }}
              onClick={() => {
                const img = new Image();
                img.onload = function () {
                  console.log("completedCrop: ", completedCrop);
                  const newCrop = {
                    unit: "px", // Can be 'px' or '%'
                    x: img.width * (completedCrop.x / 100),
                    y: img.height * (completedCrop.y / 100),
                    width: img.width * (completedCrop.width / 100),
                    height: img.height * (completedCrop.height / 100),
                  };
                  props.setCropGlobal(newCrop);
                  const canvas = document.getElementById("canvas");
                  const [imgWidth, imgHeight] = fitToContainer(
                    newCrop.width,
                    newCrop.height
                  );
                  canvas.width = imgWidth;
                  canvas.height = imgHeight;
                  canvas
                    .getContext("2d")
                    .drawImage(
                      img,
                      newCrop.x,
                      newCrop.y,
                      newCrop.width,
                      newCrop.height,
                      0,
                      0,
                      imgWidth,
                      imgHeight
                    );
                  props.setImageDimensions([newCrop.width, newCrop.height]);
                  props.setIsModalVisible(false);
                };
                img.src = `${props.file}`;
              }}
            >
              <IonIcon icon={TickIcon} style={{ marginRight: "4px" }} />
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent
          id="contentid"
          style={{
            "--background": "#F6F6F6",
            borderTop: "1px solid #BEBEBE",
            borderBottom: "1px solid #BEBEBE",
          }}
        >
          {Boolean(`${props.file}`) && (
            <div
              id="imageBackground"
              style={{
                margin: "auto",
                backgroundColor: "#7f7f7f",
                padding: "10px",
                paddingBottom: "5px",
              }}
            >
              <ReactCrop
                crop={crop}
                ruleOfThirds={true}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                onComplete={(c, percentCrop) => {
                  setCompletedCrop(percentCrop);
                }}
              >
                <img
                  id="cropRefImage"
                  alt="Crop me"
                  src={`${props.file}`}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
        </IonContent>
      </IonModal>
    </div>
  );
};

export default CropPng;
