import { useState, useEffect, useRef, useCallback } from "react";
import { getDownloadURL, getMetadata } from "firebase/storage";

const fetchUploadedFiles = async (listAllUploadedSVGs) => {
  try {
    const listResult = await listAllUploadedSVGs();
    console.log({ listResult });
    listResult.items.reverse();

    const filesWithPreview = await Promise.all(
      listResult.items.map(async (item) => {
        const url = await getDownloadURL(item);
        const metadata = await getMetadata(item);

        const date = new Date(metadata.updated);
        const dateString = date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        return {
          uri: item,
          url,
          ...metadata,
          type: "uploaded",
          date: dateString,
        };
      })
    );

    return filesWithPreview;
  } catch (error) {
    console.error("Error fetching files:", error);
    return [];
  }
};

const useFileList = (initialFiles, fetchFilesFunc) => {
  const [files, setFiles] = useState(initialFiles);
  const [visibleCount, setVisibleCount] = useState(10);
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && files.length > visibleCount) {
          setVisibleCount((prevCount) => prevCount + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [files.length, visibleCount]
  );

  useEffect(() => {
    if (files.length === 0) {
      const getFiles = async () => {
        try {
          const fetchedFiles = await fetchFilesFunc();
          setFiles(fetchedFiles);
        } catch (error) {
          console.error("Error fetching files:", error);
        }
      };
      getFiles();
    }
  }, [fetchFilesFunc, files.length]);

  return { files, visibleCount, setVisibleCount, lastElementRef };
};

export { useFileList, fetchUploadedFiles };
