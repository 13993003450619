import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import CropPng from "./CropPng";
import DesktopLayerSelector from "./DesktopLayerSelector";
import DesktopTopBar from "./DesktopTopBar";
import MainCanvas from "./MainCanvas";
import { processImage } from "./processImage";
import { useDebounceEffect } from "./useDebounceEffect";
import Navbar from "../../WebNavbar";

const ConverterDesktop = ({
  scale,
  setScale,
  file,
  setFile,
  clearFields,
  crop,
  setIsCropVisible,
  screenSize,
  setClickedColor,
  imageDimensions,
  setImageDimensions,
  originalDimensions,
  setCropGlobal,
  largeImageWarning,
  isCropVisible,
  setIsModalVisible,
  downloadSVGLink,
  isLoading,
  smoothness,
  setSmoothness,
  despeckle,
  setDespeckle,
  simplifyCurves,
  setSimplifyCurves,
  ai,
  setAi,
  colors,
  setColors,
  setSvgPreview,
  setPreviewReady,
  setSvg,
  setIsLoading,
  setDownloadSVGLink,
  setShareLink,
  svgPreview,
  saveToCloud,
  setLoadingConverter,
}) => {
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => setLoadingConverter(false), []);
  console.log({ file });
  useDebounceEffect(
    () => {
      (async () => {
        setPreviewLoading(true);
        try {
          const result = await processImage({
            colors,
            file,
            crop,
            despeckle,
            simplifyCurves,
            smoothness,
            ai,
            originalDimensions,
          });

          setSvg(result.svg);
          setSvgPreview(result.svgPreview);
          setDownloadSVGLink(result.downloadSVGLink);
        } catch (error) {
          console.error("Error processing image:", error);
        } finally {
          setPreviewLoading(false);
        }
      })();
    },
    1000,
    [
      colors,
      file,
      crop,
      despeckle,
      simplifyCurves,
      smoothness,
      ai,
      originalDimensions,
    ]
  );

  return (
    <>
      <Navbar showFileName={false} />
      <DesktopTopBar
        scale={scale}
        setScale={setScale}
        file={file}
        clearFields={clearFields}
        crop={crop}
        setIsCropVisible={setIsCropVisible}
        setIsLoading={setIsLoading}
        setPreviewReady={setPreviewReady}
        setSvg={setSvg}
        setSvgPreview={setSvgPreview}
        isLoading={isLoading}
        setDownloadSVGLink={setDownloadSVGLink}
        setShareLink={setShareLink}
        setIsModalVisible={setIsModalVisible}
        ai={ai}
        setFile={setFile}
        originalDimensions={originalDimensions}
        despeckle={despeckle}
        smoothness={smoothness}
        simplifyCurves={simplifyCurves}
        colors={colors}
        saveToCloud={saveToCloud}
        previewLoading={previewLoading}
      />
      <Row
        style={{
          outline: "1px solid #f0f0f0",
          minWidth: "500px",
          maxHeight: "calc(100vh)",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <Col
          flex="188px"
          style={{
            height: "calc(100vh - 112px)",
            // make light grey outline
            borderStyle: "solid",
            borderColor: "#cfcfcf",
            // only make the left border visible
            borderLeftWidth: "1px",
            borderRightWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            overflowY: "scroll",
          }}
        >
          <DesktopLayerSelector
            colors={colors}
            setColors={setColors}
            smoothness={smoothness}
            setSmoothness={setSmoothness}
            despeckle={despeckle}
            setDespeckle={setDespeckle}
            imageDimensions={imageDimensions}
            simplifyCurves={simplifyCurves}
            setSimplifyCurves={setSimplifyCurves}
            svgPreview={svgPreview}
            previewLoading={previewLoading}
            file={file}
            setFile={setFile}
            isLoading={isLoading}
          />
        </Col>

        <Col
          flex="auto"
          style={{
            maxWidth: "calc(100% - 188px)",
            height: "calc(100vh - 112px)",
          }}
        >
          <Col
            flex="auto"
            style={{
              backgroundColor: "#f0f0f0",
              height: "calc(100%)",
              overflowX: "scroll",
              cursor: "cursor",
            }}
          >
            <div style={{ height: "100%" }}>
              <MainCanvas
                screenSize={screenSize}
                crop={crop}
                clearFields={clearFields}
                file={file}
                scale={scale}
                setScale={setScale}
                setClickedColor={setClickedColor}
              />
              <CropPng
                setScale={setScale}
                setImageDimensions={setImageDimensions}
                originalDimensions={originalDimensions}
                cropGlobal={crop}
                setCropGlobal={setCropGlobal}
                largeImageWarning={largeImageWarning}
                file={file}
                isModalVisible={isCropVisible}
                setIsModalVisible={setIsCropVisible}
                downloadSVGLink={downloadSVGLink}
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ConverterDesktop;
